import './../Waiting.css';

function RWaiting() {
    return (
      <div className='contacts-container'>
        <p>Ждём вас на нашей свадьбе!</p>
        <h1>Катя и Давид</h1>
      </div>
    );
  }
  
  export default RWaiting;
  