import './Response.css';
import './Ornament.css';
import { Link } from 'react-router-dom';


function Menu() {
    return (
      <div className='response-container'>
        <p></p>
        <p></p>
        <img className='ornament' src='images/ornament.png'></img>
        <h1>Vacsora menü</h1>
        
        <h3>Előétel</h3>
        <p>Erdélyi padlizsánkrém, sajtkrémmel töltött fűszeres zöldpaprika, Serrano sonka</p>

        <h3>Leves</h3>
        <p>Újházi tyúkhúsleves tálban</p>

        <h3>Főétel</h3>
        <p>Vegyestál: Sertésborda Óvári módra, ötmagvas pulykamell rántva, jércemell kapros juhtúróval töltve, bacon köntösben, rántott gombafejek, kukoricás rizs, petrezselymes burgonya</p>

      
        <h3>Vegyes saláta</h3>
        <p></p>

        <h3>Éjféli menü</h3>
        <p>Töltött káposzta</p>
        <img className='ornament' src='images/ornament.png'></img>

        <img className='camera' src='images/camera.png'></img>
        <div className='glink-items'><Link to='https://drive.google.com/drive/folders/1uV4wJqSUysgMFtjcsqCTfay59mU6P2zA?usp=sharing'>Itt lesznek az esküvői fotók, illetve a "Vendégektől" mappába várjuk a feltöltéseket 😊</Link></div>

        <p></p>
      </div>
    );
  }
  
  export default Menu;
  