import './Waiting.css';

function Waiting() {
    return (
      <div className='contacts-container'>
        <p>Várunk az esküvőn!</p>
        <h1>Katy és Dávid</h1>
      </div>
    );
  }
  
  export default Waiting;
  