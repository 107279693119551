import { Link } from 'react-router-dom';
import './../Location.css';

function RLocation() {
    return (
      <div className='location-container'>
        <p>Место свадьбы:</p>
        <h1>Nagyvillám Étterem</h1>
        <div className='address'>Адрес: Visegrád, Feketehegy u., 2025</div>
        <div className='link-items'><Link to='https://maps.app.goo.gl/nhSuMz8MsiuCC1GC6'>Nagyvillám Étterem - Google Maps</Link></div>
        {/* <div className='link-items'><Link to='https://panoramap.hu/pano/nagyvillam'>Nézd meg a panorámát itt 😊</Link></div> */}
        <div className='link-items'><Link to='https://photos.app.goo.gl/1nFZiNMkVpDMZTjV6'>Посмотреть панораму можно тут 😊</Link></div>
        
        
      </div>
    );
  }
  
  export default RLocation;
  