import React from 'react';
import '../../App.css';
import Intro from '../Intro';
import Invitation from '../Invitation';
import Location from '../Location';
import Schedule from '../Schedule';
import Response from '../Response';
import Waiting from '../Waiting';
import Ornament from '../Ornament';
import Menu from '../Menu';


function Home({ name }) {
  return (
    <>
      <Intro/>
      {/* <Ornament/> */}
      <Invitation name={name}/>
      <Location/>
      <Schedule/>
      {/* <Response/> */}
      <Menu/>
      <Waiting/>
    </>
  );
}

export default Home;