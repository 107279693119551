import React from 'react';
import '../App.css';
import './Intro.css';
import './Default.css';

function Intro() {
  return (
    <div className='intro-container'>
      <h1>
        <span className='aligner'>Katy</span>
        <span className='aligner andsign'> & </span>
        <span className='aligner'>Dávid</span>
      </h1>
      <p>2024. 08. 30.</p>

    </div>
  );
}

export default Intro;
