// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ornament {
  margin-bottom: 50px;
  width: 400px;
}
  
@media screen and (max-width: 960px) {
  .ornament {
    width: 200px;
  }
   }
  `, "",{"version":3,"sources":["webpack://./src/components/Ornament.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;AACd;;AAEA;EACE;IACE,YAAY;EACd;GACC","sourcesContent":[".ornament {\n  margin-bottom: 50px;\n  width: 400px;\n}\n  \n@media screen and (max-width: 960px) {\n  .ornament {\n    width: 200px;\n  }\n   }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
