import './Schedule.css';
import React, { useRef, useEffect } from 'react';


const ScheduleTemplate = ({ events }) => {
  const eventRefs = useRef([]);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Trigger animation when 50% of event is visible
    };
  
    const handleIntersect = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add('slide-in'); // Add slide-in class when event is in view
        }
      });
    };
  
    const observer = new IntersectionObserver(handleIntersect, options);
  
    eventRefs.current.forEach((ref) => {
      observer.observe(ref);
    });
  
    return () => {
      observer.disconnect();
    };
  }, [events]);


  return (
    <div className="schedule">
      {events.map((event, index) => (
        <div key={index} className="event" ref={(el) => (eventRefs.current[index] = el)}>
          <div className="event-content">
            <div className="event-image">
              <img src={'rings.svg'} alt={event.name} />
            </div>
            <div className="event-details">
              <div className="event-time">{event.time}</div>
              <div className="event-name">{event.name}</div>
            </div>
          </div>
          {index < events.length - 1 && <div className="separator"></div>}
        </div>
      ))}
    </div>
  );
};


function Schedule() {
    const events = [
      {
        time: '14:00',
        name: 'Indulás busszal Budapestről',
        icon: 'images/bus.png'
      },
      {
        time: '15:30',
        name: 'Találkozó az étteremnél'
      },
      {
        time: '16:00',
        name: 'Ceremónia'
      },
      {
        time: '16:30',
        name: 'Fotózás és gratuláció'
      },
      {
        time: '18:00',
        name: 'Vacsora'
      },
      {
        time: '21:00',
        name: 'Torta és buli'
      },
      {
        time: '22:00',
        name: 'Menyecsketánc'
      }
    ]

    return (
      <div className='schedule-container'>
        <h1>Program</h1>
        <ScheduleTemplate events={events} />
        
      </div>
    );
  }
  
  export default Schedule;
  