import React from 'react';
import '../App.css';
import './Ornament.css';
import './Default.css';


function Ornament() {
  return (
    <div className='ornament-container'>
      <img src='images/ornament.png'></img>
    </div>
  );
}

export default Ornament;
