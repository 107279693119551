import './Response.css';
import './Ornament.css';
import { Link } from 'react-router-dom';


function Response() {
    return (
      <div className='response-container'>
        <p></p>
        <p></p>
        <img className='ornament' src='images/ornament.png'></img>
        <p>Kérjük szépen, hogy az alábbi linken található kérdések megválaszolásával segítsd az esküvő szervezését:</p>
        <div className='glink-items'><Link to='https://forms.gle/KP4BkFmT3odoK3YW6'>Kérdőív</Link></div>
        <p>Kérjük, hogy jelezz vissza legkésőbb 2024. június 30-ig. Köszönjük!</p>
        <p></p>
        <p></p>
      </div>
    );
  }
  
  export default Response;
  