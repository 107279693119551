// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/images/katydavid_new.JPG", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.contacts-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    background-color: rgb(255, 248, 239);
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}); /* Default background image */
    background-size: cover;
    background-position: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2  );
    object-fit: contain;
  }
  
  .contacts-container > h1 {
    color: #ffffff;
    font-size: 5rem;
    font-family: "Great Vibes", cursive;
    font-weight: 500;
    font-style: normal;
    text-align: center;
    margin-bottom: 20px;
  }


  .contacts-container > p {
    color: #ffffff;
    font-size: 3rem;
    font-family: "Great Vibes", cursive;
    font-weight: 500;
    font-style: normal;
    margin-bottom: -3.5rem;
  }
  

@media screen and (max-width: 960px) {
    .contacts-container > h1 {
        font-size: 3.5rem;
    }

    .contacts-container > p {
      color: #ffffff;
      font-size: 2.3rem;
      margin-bottom: -2.5rem;
    }

    .contacts-container {
      background-position: calc(50% + 4rem) center; 
  }
}
  `, "",{"version":3,"sources":["webpack://./src/components/Waiting.css"],"names":[],"mappings":";AACA;IACI,aAAa;IACb,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,oBAAoB;IACpB,mBAAmB;IACnB,oCAAoC;IACpC,mDAAmD,EAAE,6BAA6B;IAClF,sBAAsB;IACtB,2BAA2B;IAC3B,mDAAmD;IACnD,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,eAAe;IACf,mCAAmC;IACnC,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,mBAAmB;EACrB;;;EAGA;IACE,cAAc;IACd,eAAe;IACf,mCAAmC;IACnC,gBAAgB;IAChB,kBAAkB;IAClB,sBAAsB;EACxB;;;AAGF;IACI;QACI,iBAAiB;IACrB;;IAEA;MACE,cAAc;MACd,iBAAiB;MACjB,sBAAsB;IACxB;;IAEA;MACE,4CAA4C;EAChD;AACF","sourcesContent":["\n.contacts-container {\n    height: 100vh;\n    width: 100%;\n    display: flex;\n    flex-direction: column;\n    justify-content: end;\n    align-items: center;\n    background-color: rgb(255, 248, 239);\n    background: url('/public/images/katydavid_new.JPG'); /* Default background image */\n    background-size: cover;\n    background-position: center;\n    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2  );\n    object-fit: contain;\n  }\n  \n  .contacts-container > h1 {\n    color: #ffffff;\n    font-size: 5rem;\n    font-family: \"Great Vibes\", cursive;\n    font-weight: 500;\n    font-style: normal;\n    text-align: center;\n    margin-bottom: 20px;\n  }\n\n\n  .contacts-container > p {\n    color: #ffffff;\n    font-size: 3rem;\n    font-family: \"Great Vibes\", cursive;\n    font-weight: 500;\n    font-style: normal;\n    margin-bottom: -3.5rem;\n  }\n  \n\n@media screen and (max-width: 960px) {\n    .contacts-container > h1 {\n        font-size: 3.5rem;\n    }\n\n    .contacts-container > p {\n      color: #ffffff;\n      font-size: 2.3rem;\n      margin-bottom: -2.5rem;\n    }\n\n    .contacts-container {\n      background-position: calc(50% + 4rem) center; \n  }\n}\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
