import React from 'react';
import '../../App.css';
import RIntro from '../russian/RIntro';
import RInvitation from '../russian/RInvitation';
import RLocation from '../russian/RLocation';
import RSchedule from '../russian/RSchedule';
import RWaiting from '../russian/RWaiting';


function RHome() {
  return (
    <>
      <RIntro/>
      <RInvitation/>
      <RLocation/>
      <RSchedule/>
      <RWaiting/>
    </>
  );
}

export default RHome;