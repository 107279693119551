import './App.css';
import Home from './components/pages/Home';
import RHome from './components/pages/RHome';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useEffect } from 'react';

function ExternalRedirect({ to }) {
  useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null; // Optional: You can return a loading spinner or a message here
}

function App() {
  return (
    <>
    <Router>
      <Routes>
      <Route path="/" element={<Home name="Vendégek" />} />
      <Route path="/katysfamily" element={<RHome />} />
      <Route path="/anya" element={<Home name="Anya" />} />
      <Route path="/mama" element={<Home name="Mama" />} />
      <Route path="/apa" element={<Home name="Apa és Nelli" />} />
      <Route path="/nettiek" element={<Home name="Netti és Ádám" />} />
      <Route path="/tomiek" element={<Home name="Tomi, Zsuzsa és Anna" />} />
      <Route path="/norbiek" element={<Home name="Norbi és Rozi" />} />
      <Route path="/andris" element={<Home name="Andris" />} />
      <Route path="/csabaek" element={<Home name="Csaba és Wonny" />} />
      <Route path="/juliek" element={<Home name="Juli és Attila" />} />
      <Route path="/gaborek" element={<Home name="Gábor és Viki" />} />
      <Route path="/zsolti" element={<Home name="Zsolti" />} />
      <Route path="/gabi" element={<Home name="Gabi" />} />

      <Route path="/photos" element={<ExternalRedirect to="https://drive.google.com/drive/folders/1uV4wJqSUysgMFtjcsqCTfay59mU6P2zA?usp=sharing" />} />

      </Routes>
    </Router>
  </>
  );
}


export default App;
