import Countdown, {zeroPad} from 'react-countdown';
import './Invitation.css';
import './Ornament.css';

function Invitation({name}) {
    const renderer = ({days, hours, minutes, seconds}) => {
        if (days == 0 && hours == 0 && minutes == 0 && seconds == 0) {
          return <img className='weddinggif' src='images/wedding.webp'></img>
        }

        return <div>
            <p className='ptext'>Már csak ennyi idő van hátra:</p>
            <p className='countdown'>
            <table className='countdowntable'>
              <tr className='numberrow'>
                <td>{days}</td>
                <td>:</td>
                <td>{hours}</td>
                <td>:</td>
                <td>{zeroPad(minutes)}</td>
                <td>:</td>
                <td>{zeroPad(seconds)}</td>
                </tr>

                <tr className='ptext'>
                <td>nap</td>
                <td></td>
                <td>óra</td>
                <td></td>
                <td>perc</td>
                <td></td>
                <td>mp</td>
                </tr>
            </table>
            </p>
          
            </div>
    };

    return (
      <div className='invitation-container'>
        <img className='ornament' src='images/ornament.png'></img>
        <p className='pnice'>Kedves {name}!</p>
        <p className='pnice'>Sok szeretettel meghívunk az életünket összekötő nagy napunkra, az esküvőnkre.</p>
        
        <p></p>
        <p className='ptext'>Az esküvő dátuma:</p>
        <p className='weddingdate'>2024. 08. 30.</p>

        <p></p>
        <Countdown date={new Date("2024-08-30T16:00:00")} renderer={renderer}/>
        
        {/* <p>We will be glad to see you among our guests!</p> */}
        <p></p>
        <p></p>
        <p></p> 
        <p></p>

        
      </div>
    );
  }
  
  export default Invitation;
  